
















import { defineComponent } from "@vue/composition-api";
import defaultLayout from "@/layouts/default.vue";
import iframeLayout from "@/layouts/iframe.vue";

export default defineComponent({
  components: {
    defaultLayout,
    iframeLayout
  }
});
