import Vue from "vue";
import VueI18n from "vue-i18n";
import esCO from "./es-CO.json";

Vue.use(VueI18n);

const messages = {
  esCO: { ...esCO }
};

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "esCO",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "esCO",
  messages
});

export const useTranslation = () => {
  return {
    ...i18n,
    t: (key: string) => i18n.t(key)
  };
};

export default i18n;
