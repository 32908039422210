export enum DeliveryType {
  AGENT = "3_PICKUP",
  CAV = "3_PICKUP",
  "3_PICKUP" = "3_PICKUP"
}

export enum CancelReason {
  EXPIRED = "1_ORDEN_CADUCADA",
  CLIENT_CANCELED = "2_CANCELADO_CLIENTE",
  DELIVERY_FAILURE = "3_FALLA_DELIVERY",
  CANCELED = "03_CANCELADO"
}

export enum DocumentType {
  CC = "CC",
  NIT = "NIT",
  CE = "CE"
}

export enum DeliveryConfirmationReason {
  DELIVERED = "ENTREGADO_CLIENTE",
  REJECTED = "RECHAZADO_AGENTE",
  RECEIVED_AGENT = "RECIBIDO_AGENTE",
  REJECTED_WRONG_PRODUCT = "RECHAZADO_PRODUTO_ERRADO",
  REJECTED_DEFECTIVE_PRODUCT = "RECHAZADO_PRODUTO_DEFECTUOSO",
  RETRACTED = "RETRACTO"
}

export enum OrderStatus {
  CREATED = "01_CREADO",
  PROCESSING = "02_EN_ALISTAMIENTO",
  READY_FOR_DELIVERY = "03_LISTO_PARA_DELIVERY",
  READY_FOR_COLLECTION = "04_LISTO_PARA_RECOLECCION",
  IN_TRANSIT = "05_EN_TRANSITO",
  DELIVERED_CENTER = "06_ENTREGADO_CENTRO",
  DELIVERED_AGENT = "0601_ENTREGADO_AGENTE",
  RETURN = "0602_DEVOLUCION",
  DELIVERED_ASSESSOR = "07_ENTREGADO_ASESOR",
  DELIVERED_CLIENT = "08_ENTREGADO_CLIENTE",
  DELIVERED_CLIENT_EXCHANGE = "0801_ENTREGADO_CLIENTE_CAMBIO",
  RESCHEDULED = "09_REAGENDADO",
  NOT_DELIVERED = "10_NO_ENTREGADO",
  DISPONIBILITY_ERROR = "11_ERROR_POR_DISPONIBILIDAD",
  CANCELED = "12_CANCELADO",
  PROCESSING_ERROR = "13_ERROR_ALISTAMIENTO",
  CANCELING = "14_EN_CANCELACION",
  ACCOUNTING_ERROR = "15_ERROR_CONTABILIZACION",
  COVERAGE_ERROR = "16_ERROR_COBERTURA",
  SCHEDULED = "17_AGENDADO",
  PRESALE_CREATED = "1800_PREVENTA_CREADA",
  PRESALE_COVERAGE_ERROR = "1801_PREVENTA_ERROR_COBERTURA",
  PRESALE_CONFIRMED = "1802_PREVENTA_CONFIRMADA",
  PRESALE_SAP_ERROR = "1803_PREVENTA_ERROR_SAP",
  RESERVED = "19_RESERVADO",
  NOT_RESERVED_UNAVAILABLE_STOCK = "1901_NO_RESERVADO_STOCK_INDISPONIBLE",
  NOT_RESERVED_SAP_ERROR = "1902_NO_RESERVADO_ERROR_SAP",
  OBJECT_AUTHORIZATION_FAILURE = "1903_FALLA_AUTORIZACION_OBJETO",
  ERROR = "99_ERROR",
  ERROR_SAP_ORDER_TRANSFER = "990201_ERROR_PEDIDO_TRASLADO_SAP",
  ERROR_REQUEST_PROCESSING = "990202_ERROR_SOLICITAR_ALISTAMEINTO",
  ERROR_ASSIGN_SERIALS = "990203_ERROR_ASIGNAR_SERIALES",
  ERROR_UPDATE_PROCESSING = "990204_ERROR_ACTUALIZAR_ALISTAMIENTO",
  ERROR_UPDATE_DELIVERY = "990501_ERROR_ACTUALIZAR_DELIVERY",
  ERROR_UPDATE_ORDER_STATUS = "990001_ERROR_ACTUALIZAR_ESTADO_DE_LA_ORDEN",
  ALISTAMIENTO_SOLICITADO = "0201_ALISTAMIENTO_SOLICITADO",
  CONFIRMACION_PAGO = "0102_CONFIRMACION_PAGO",
  PAGO_RECHAZADO = "1904_PAGO_RECHAZADO"
}

export const GroupedStatus = [
  {
    name: "Gestionables",
    values: process.env.VUE_APP_FILTER_INTERACTIVE_STATES?.split("|")
  },
  {
    name: "Errores",
    values: process.env.VUE_APP_FILTER_ERROR_STATES?.split("|")
  },
  {
    name: "Otros",
    values: process.env.VUE_APP_FILTER_OTHER_STATES?.split("|")
  }
];
