import Vue from "vue";
import Vuex from "vuex";

import { RootState, orders, centers, query } from "./modules/";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store<RootState>({
  modules: { query, orders, centers },
  strict: debug
});

export const useStore = () => store;

export default store;
