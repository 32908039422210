



































import {
  defineComponent,
  reactive,
  computed,
  onMounted
} from "@vue/composition-api";
import { FilterGestionOrdenes } from "@/containers";
import { OrderListParams } from "@/interfaces";
import { useStore } from "@/store";
import FilterInterface from "./Filter/FilterInterface";
import { DeliveryType } from "@/enums";

export default defineComponent({
  components: {
    FilterGestionOrdenes
  },
  setup() {
    const store = useStore();
    const items = computed(() => store.getters.getPedidos);
    const listInfo = computed(() => store.getters.getListInfo);

    const table = reactive({
      items,
      headers: [
        { text: "Orden", value: "orderInfo.channelOrderNbr", sortable: true },
        {
          text: "Número de documento",
          value: "customer.docNumber",
          sortable: true
        },
        {
          text: "Pedido SAP",
          value: "items.0.stockReservation.sap.sapOrderId",
          sortable: true
        },
        { text: "Creado el", value: "orderInfo.creationDate", sortable: true },
        { text: "Estado", value: "orderInfo.orderState", sortable: true }
      ]
    });

    const requestParams: OrderListParams = reactive({
      pagina: 1,
      cantidad_registros_por_pagina: 20,
      lista_tipo_entrega: DeliveryType.CAV + "|" + DeliveryType.AGENT
    });

    const clearList = () => {
      store.dispatch("clearList");
    };

    const fetchList = () => {
      store.dispatch("fetchList", requestParams);
    };

    const onSearch = (filter: FilterInterface) => {
      Object.entries(filter).forEach(([field, value]) => {
        const key = field as keyof FilterInterface;

        requestParams[key] = value || undefined;
      });

      requestParams.pagina = 1;

      fetchList();
    };

    const onPageChange = (page: number) => {
      requestParams.pagina = page;
      fetchList();
    };

    onMounted(() => {
      clearList();
    });

    return {
      table,
      onSearch,
      clearList,
      onPageChange,
      listInfo,
      requestParams
    };
  }
});
