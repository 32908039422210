import { useTranslation } from "@/i18n";
const { t } = useTranslation();

const $rules = {
  required: (value: any) => !!value || t("rules.required"),
  number: (value: any) =>
    !value || /^\d*$/.test(`${value}`) || t("rules.number"),
  numbersAndDots: (value: any) =>
    !value || /^[\d.]*$/.test(`${value}`) || t("rules.numbersAndDots")
};

const rulesMixin = {
  setup() {
    return { $rules };
  }
};

export default rulesMixin;
