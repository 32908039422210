import { State } from "./state";
import { GetterTree } from "vuex";

const getters: GetterTree<State, State> = {
  getCenter(state: State) {
    return state.center.data;
  },
  getCenterLoading(state: State) {
    return state.center.loading;
  }
};

export default getters;
