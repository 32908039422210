<template>
  <div>
    <multiselect
      v-model="selectionVal"
      :options="options"
      :multiple="true"
      group-values="values"
      group-label="name"
      :close-on-select="false"
      :taggable="false"
      :clear-on-select="false"
      :group-select="true"
      :placeholder="selectionOpen ? computedLabel : 'Estados'"
      :select-label="labels.select"
      :deselect-label="labels.deselect"
      :selected-label="labels.selected"
      :select-group-label="labels.selectGroup"
      :deselect-group-label="labels.deselectGroup"
      track-by="name"
      label="name"
    >
      <template slot="tag">{{ "" }}</template>
      <template slot="selection" slot-scope="{ values, search, isOpen }">
        <span
          class="multiselect__single"
          v-if="values.length &amp;&amp; !isOpen"
        >
          {{ computedLabel }}
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { GroupedStatus } from "@/enums/order";

export default {
  props: {
    value: {
      type: String
    }
  },
  components: {
    Multiselect
  },
  data() {
    const labels = {
      select: "",
      deselect: "",
      selected: "",
      selectGroup: "Grupo",
      deselectGroup: "Grupo"
    };
    const namedGroupedStatus = GroupedStatus.map(gs => {
      return {
        name: gs.name,
        values: gs.values.map(v => {
          return { name: this.$t(`enum.orderStatus.${v}`), code: v };
        })
      };
    });
    const selectionOpen = false;
    return {
      selection: namedGroupedStatus[0].values,
      selectionOpen,
      labels,
      options: namedGroupedStatus
    };
  },
  computed: {
    selectionVal: {
      get() {
        return this.selection;
      },
      set(val) {
        this.selection = val;
        this.$emit("input", this.selection.map(s => s.code).join("|"));
      }
    },
    computedLabel() {
      const vValue = [];
      for (const option of this.options) {
        if (option.values.every(r => this.selection.includes(r))) {
          vValue.push({ name: option.name });
        } else {
          vValue.push(...option.values.filter(r => this.selection.includes(r)));
        }
      }
      const label = vValue.map(v => v.name).join(", ");
      if (label.length > 40) {
        return `${this.selection.length} opciones seleccionadas`;
      }
      return label;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />

<style>
.multiselect * {
  font-size: small;
  border-color: #9f9f9f;
}

.multiselect__tags * {
  font-size: small;
}

.multiselect__option--group {
  background-color: #cfcfcf;
}

.multiselect__option--group * {
  font-size: medium;
}

.multiselect__content {
  padding-left: 0px !important;
}
</style>
