import { Order, OrderListParams } from "@/interfaces";
import i18n from "@/i18n";
import instance from "@/services/orderApi/instance";
import { to } from "nh-utils";

const request = async (params: OrderListParams): Promise<Order | null> => {
  const [err, res] = await to(instance.get("/order/" + params.id_pedido));

  if (err && !res) {
    alert(i18n.t("api.defaultErrorMessage").toString());
    return null;
  }

  const response: Order | null = res?.data;

  return response;
};

export default request;
