import { GestionOrdenes } from "@/views/";
import { GestionOrdenesContainer, ConsultaContainer } from "@/containers";
import { Route, RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "",
    component: GestionOrdenes,
    children: [
      {
        path: "/",
        name: "gestionsOrdenes",
        component: GestionOrdenesContainer,
        meta: {
          title: "Gestión de Ordenes",
          breadcrumb: [
            {
              text: "Gestion de Ordenes",
              disabled: true
            }
          ],
          layout: "iframe"
        }
      },
      {
        path: "/:order",
        name: "recepcionProductos",
        component: ConsultaContainer,
        meta: {
          title: "Recepción Productos",
          breadcrumb: (route: Route) => {
            return [
              {
                text: "Gestion de Ordenes",
                href: "/"
              },
              {
                text: "Consulta",
                href: `/${route.params.order}`,
                disabled: true
              }
            ];
          },
          layout: "iframe"
        }
      }
    ]
  }
];

export default routes;
