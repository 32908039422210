import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n/";
import vuetify from "./plugins/vuetify";
import VueCompositionAPI from "@vue/composition-api";
import { rules } from "./utils/mixins";

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.mixin(rules);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
