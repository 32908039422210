import { State } from "./state";
import { ActionTree, ActionContext } from "vuex";

const actions: ActionTree<State, State> = {
  async buildQueryParams(
    context: ActionContext<State, State>,
    query: Record<string, string>
  ) {
    const params: State = {
      idUser: query.idUser || null
    };

    context.commit("changeQueryParams", params);
  }
};

export default actions;
