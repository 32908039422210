import { State } from "./state";
import { MutationTree } from "vuex";
import { Center } from "@/interfaces";

const mutations: MutationTree<State> = {
  startLoadingCenter(state: State) {
    state.center.loading = true;
  },
  stopLoadingCenter(state: State) {
    state.center.loading = false;
  },
  changeCenter(state: State, result: Center) {
    state.center.data = result;
    state.center.loading = false;
  }
};

export default mutations;
