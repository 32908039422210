









import { defineComponent } from "@vue/composition-api";
import { Menu } from "@/components";

export default defineComponent({
  components: {
    Menu
  }
});
