import instance from "@/services/centerAgentApi/instance";
import { to } from "nh-utils";
import { AgentCenterParams, Center } from "@/interfaces";
import i18n from "@/i18n";

const request = async (params: AgentCenterParams): Promise<Center | null> => {
  const [err, res] = await to(
    instance.get(`/warehouse/${params.where.dealer_hijo}`)
  );

  if (err && !res) {
    alert(i18n.t("api.defaultErrorMessage").toString());
    return null;
  }

  const result = res.data;

  const center: Center = {
    name: result.warehouseName,
    address: [result.department, result.city, result.addressDetail]
      .filter(value => !!value)
      .join(", "),
    schedule: result.extInfor.workHourDesc
  };

  return center;
};

export default request;
