























































































































































import {
  defineComponent,
  onMounted,
  computed,
  reactive,
  ref,
  watch
} from "@vue/composition-api";
import { PSCardDetails, KeyboardEventHandler } from "@/components/";
import { ConfirmDeliveryDialog, RejectDeliveryDialog } from "@/containers/";
import { useStore } from "@/store";
import { Order } from "@/interfaces";
import { DeliveryType, OrderStatus } from "@/enums";
import { useRouter } from "@/router";

export default defineComponent({
  components: {
    PSCardDetails,
    KeyboardEventHandler,
    ConfirmDeliveryDialog,
    RejectDeliveryDialog
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const orderNbr = router.currentRoute.params.order;
    const expanded = reactive([]);

    const correctStatusForDeliveryType = computed(() => {
      const status = store.getters.getPedido?.orderInfo.orderState;
      const deliveryType = store.getters.getPedido?.orderInfo.deliveryType;

      return (
        (deliveryType === DeliveryType.AGENT &&
          status === OrderStatus.DELIVERED_AGENT) ||
        (deliveryType === DeliveryType.CAV &&
          status === OrderStatus.DELIVERED_ASSESSOR)
      );
    });
    const pickupDelivery = computed(
      () =>
        store.getters.getPedido?.orderInfo.deliveryType === DeliveryType.AGENT
    );
    const deliveredToAgent = computed(
      () =>
        store.getters.getPedido?.orderInfo.orderState === OrderStatus.IN_TRANSIT
    );

    const loading = computed(
      () => store.getters.getViewLoading || store.getters.getCenterLoading
    );
    const status = computed(
      () => store.getters.getPedido?.orderInfo.orderState
    );
    const orderNumber = computed(
      () => store.getters.getPedido?.orderInfo.channelOrderNbr
    );
    const id_alistamiento_ol = computed(
      () =>
        ([...store.getters.getPedido?.items] as Order["items"])[0]
          ?.logisticsCode
    );

    const center = computed(() => store.getters.getCenter || {});

    const mainTableItems = computed(() =>
      store.getters.getPedido ? [store.getters.getPedido] : []
    );
    const mainTable = reactive({
      items: mainTableItems,
      headers: [
        { text: "TD", value: "customer.docType" },
        {
          text: "Número de documento",
          value: "customer.docNumber"
        },
        { text: "Nombre del Cliente", value: "customer.name" },
        {
          text: "Pedido eCommerce",
          value: "orderInfo.channelOrderNbr"
        },
        {
          text: "Pedido SAP",
          value: "items.0.stockReservation.sap.sapOrderId"
        },
        { text: "Alistamiento", value: "items.0.logisticsCode" },
        {
          text: "Ponto de Venta",
          value: "pickUpInfo.warehouseCenter"
        }
      ]
    });
    console.log(mainTable);

    const contentTableItems = computed(() => store.getters.getPedido?.items);
    const contentTable = reactive({
      items: contentTableItems,
      headers: [
        {
          text: "SKU",
          value: "productInfo.channelProductCode",
          sortable: true
        },
        {
          text: "REFERENCIA",
          value: "productInfo.productDescription",
          sortable: true
        },
        { text: "SERIALES", value: "serials", sortable: false },
        { text: "MIN", value: "minMsisdn", sortable: true },
        { text: "CANTIDAD", value: "quantity", sortable: true }
      ]
    });

    const listening = ref(true);
    const dialogs = reactive({
      confirm: { open: false },
      reject: { open: false },
      warning: { open: false }
    });

    const loadData = () => {
      store.dispatch("fetchOrder", orderNbr);
    };

    onMounted(() => {
      loadData();
    });

    watch(
      () => store.getters.getPedido,
      order => {
        store.dispatch("fetchCenter", order);
        dialogs.warning.open =
          order.estado_pedido === OrderStatus.READY_FOR_COLLECTION;
      }
    );

    const enterHandler = () => {
      const dialogsOpen =
        dialogs.confirm.open || dialogs.reject.open || dialogs.warning.open;
      const canOpenConfimDialog = !loading && correctStatusForDeliveryType;

      if (!dialogsOpen && canOpenConfimDialog) dialogs.confirm.open = true;
      else if (dialogs.warning.open) dialogs.warning.open = false;
    };

    return {
      orderNumber,
      status,
      id_alistamiento_ol,
      mainTable,
      contentTable,
      center,
      expanded,
      pickupDelivery,
      deliveredToAgent,
      correctStatusForDeliveryType,
      loading,
      loadData,
      listening,
      enterHandler,
      dialogs
    };
  }
});
