import instance from "@/services/orderApi/instance";
import { to } from "nh-utils";
import { OrderList, OrderListParams } from "@/interfaces";
import i18n from "@/i18n";

const request = async (params: OrderListParams): Promise<OrderList | null> => {
  const [err, res] = await to(instance.get("/order", { params }));

  if (err && !res) {
    alert(i18n.t("api.defaultErrorMessage").toString());
    return null;
  }

  return res?.data;
};

export default request;
