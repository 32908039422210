















































































import { defineComponent, reactive } from "@vue/composition-api";
import { DatePicker, FilterBox } from "@/components/";
import { DocumentType } from "@/enums/";
import { useTranslation } from "@/i18n";
import FilterInterface from "./FilterInterface";
import MultipleSelect from "@/components/MultipleSelect/MultipleSelect.vue";
import { GroupedStatus } from "@/enums/order";

export default defineComponent({
  name: "FilterGestionOrdenes",
  components: {
    MultipleSelect,
    DatePicker,
    FilterBox
  },
  emits: ["search", "clear"],
  setup(_, { emit }) {
    const { t } = useTranslation();

    const docTypes = reactive(
      Object.values(DocumentType).map(value => ({
        text: t(`enum.documentType.${value}`),
        value
      }))
    );

    const formState: {
      valid: boolean;
      oneFilled: string | boolean;
    } = reactive({ valid: true, oneFilled: true });

    const filterState: FilterInterface = reactive({
      tipo_documento: "",
      numero_documento: "",
      numero_orden: "",
      id_pedido_venta_sap: "",
      fecha_inicial: "",
      fecha_final: "",
      lista_estados: GroupedStatus[0].values?.join("|")
    });

    const onSearch = () => {
      if (
        filterState.tipo_documento ||
        filterState.numero_orden ||
        filterState.id_pedido_venta_sap ||
        filterState.fecha_inicial
      )
        emit("search", { ...filterState });
      else {
        formState.valid = false;
        formState.oneFilled = t("gestionOrdenes.requiredAtLeastOne") as string;
      }
    };

    const onClear = () => {
      filterState.tipo_documento = "";
      filterState.numero_documento = "";
      filterState.numero_orden = "";
      filterState.id_pedido_venta_sap = "";
      filterState.fecha_inicial = "";
      filterState.fecha_final = "";
      filterState.lista_estados = GroupedStatus[0].values?.join("|");

      formState.valid = true;

      emit("clear");
    };

    const onFill = (value: string) => {
      if (value) {
        formState.oneFilled = true;
      }
    };

    return {
      formState,
      filterState,
      docTypes,
      $rules: {
        document: (value: string) => {
          if (
            value ||
            (filterState.tipo_documento && filterState.numero_documento) ||
            (!filterState.tipo_documento && !filterState.numero_documento)
          )
            return true;

          return t("gestionOrdenes.requiredBothDocumentFields");
        },
        startDate: (value: string) => {
          if (!value) {
            if (filterState.fecha_final)
              return t("gestionOrdenes.requiredBothDates");

            return true;
          }

          if (!filterState.fecha_final) return true;

          return (
            new Date(value) <= new Date(filterState.fecha_final) ||
            t("gestionOrdenes.invalidStartDate")
          );
        },
        endDate: (value: string) => {
          if (!value) {
            if (filterState.fecha_inicial)
              return t("gestionOrdenes.requiredBothDates");

            return true;
          }

          if (!filterState.fecha_inicial) return true;

          return (
            new Date(value) >= new Date(filterState.fecha_inicial) ||
            t("gestionOrdenes.invalidEndDate")
          );
        }
      },
      onSearch,
      onClear,
      onFill
    };
  }
});
