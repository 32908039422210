

























































































import {
  defineComponent,
  computed,
  reactive,
  ref,
  watch
} from "@vue/composition-api";
import { KeyboardEventHandler } from "@/components/";
import { useStore } from "@/store";

import { DeliveryConfirmationReason, OrderStatus } from "@/enums";

export default defineComponent({
  name: "RejectDeliveryDialog",
  components: {
    KeyboardEventHandler
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  emits: ["hasEnterControl", "success"],
  setup(props, { emit }) {
    const store = useStore();

    const order = computed(() => store.getters.getPedido);
    const confirmationRequestInfo = computed(
      () => store.getters.getConfirmationRequestInfo
    );

    const listening = ref(false);

    const dialogs = reactive({
      rejected: {
        open: false
      },
      reject: {
        open: props.value,
        reason: null,
        reasonText: ""
      }
    });

    const rejectReasons = reactive([
      {
        text: "Producto errado",
        value: DeliveryConfirmationReason.REJECTED_WRONG_PRODUCT
      },
      {
        text: "Producto defectuoso",
        value: DeliveryConfirmationReason.REJECTED_DEFECTIVE_PRODUCT
      },
      ...(order.value?.estado_pedido === OrderStatus.READY_FOR_COLLECTION
        ? [{ text: "Retracto", value: DeliveryConfirmationReason.RETRACTED }]
        : [])
    ]);

    watch(
      () => props.value,
      newValue => {
        dialogs.reject.open = newValue;

        if (newValue) listening.value = true;
      }
    );

    watch(
      () => listening.value,
      newValue => {
        emit("hasEnterControl", newValue);
      }
    );

    const sendRequest = () => {
      store.dispatch("confirmDelivery", {
        order: order.value,
        motivo: dialogs.reject.reason,
        motivo_rechazo: dialogs.reject.reasonText
      });
    };

    watch(
      () => [
        store.getters.getConfirmationRequestInfo.loading,
        store.getters.getConfirmationRequestInfo.error,
        store.getters.getConfirmationRequestInfo.is_rejecting
      ],
      ([loading, error, is_rejecting]) => {
        if (is_rejecting && !loading && !error) {
          emit("input", false);
          dialogs.reject.open = false;
          dialogs.reject.reason = null;
          dialogs.reject.reasonText = "";

          dialogs.rejected.open = true;

          emit("success");
        }
      }
    );

    const enterHandler = () => {
      const loadingRequest = confirmationRequestInfo.value.loading;
      const canReject = dialogs.reject.reason && dialogs.reject.reasonText;

      if (listening.value) {
        if (dialogs.rejected.open) {
          dialogs.rejected.open = false;
          listening.value = false;
        } else if (!loadingRequest && canReject) sendRequest();
      }
    };

    return {
      status,
      sendRequest,
      dialogs,
      rejectReasons,
      confirmationRequestInfo,
      listening,
      enterHandler
    };
  }
});
