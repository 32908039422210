import { OrderList, Order } from "@/interfaces";

interface OrderStateInterface {
  list: OrderList & {
    loading: boolean;
  };
  view: {
    order: Order | null;
    loading: boolean;
  };
  confirm: {
    loading: boolean;
    error: boolean;
    is_rejecting: boolean;
  };
}

const state: OrderStateInterface = {
  list: {
    hasNextPage: true,
    limit: 20,
    page: 0,
    total_objects: 0,
    total_pages: 0,
    loading: false,
    pedidos: []
  },
  view: {
    order: null,
    loading: false
  },
  confirm: {
    loading: false,
    error: false,
    is_rejecting: false
  }
};

export type State = typeof state;
export default state;
