




import { defineComponent, onBeforeUnmount, watch } from "@vue/composition-api";

export default defineComponent({
  name: "KeyboardEventHandler",
  props: {
    listening: {
      type: Boolean,
      default: true
    }
  },
  emits: ["keyup", "enter"],
  setup(props, { emit }) {
    const handler = (e: KeyboardEvent) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") emit("enter");

      emit("keyup", e.code);
    };

    if (props.listening) window.addEventListener("keyup", handler);

    onBeforeUnmount(() => {
      if (props.listening) window.removeEventListener("keyup", handler);
    });

    watch(
      () => props.listening,
      newValue => {
        if (newValue) window.addEventListener("keyup", handler);
        else window.removeEventListener("keyup", handler);
      }
    );
  }
});
