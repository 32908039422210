












import { defineComponent, onMounted, computed } from "@vue/composition-api";
import { useStore } from "@/store";
import { useRouter } from "@/router";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      store.dispatch("buildQueryParams", router.currentRoute.query);
    });

    return {};
  }
});
