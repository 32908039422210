




























































































import {
  defineComponent,
  computed,
  reactive,
  ref,
  watch
} from "@vue/composition-api";
import { KeyboardEventHandler } from "@/components/";
import { useStore } from "@/store";
import { Order } from "@/interfaces";
import { DeliveryConfirmationReason, OrderStatus } from "@/enums";

export default defineComponent({
  name: "ConfirmDeliveryDialog",
  components: {
    KeyboardEventHandler
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  emits: ["hasEnterControl", "success"],
  setup(props, { emit }) {
    const store = useStore();

    const order = computed(() => store.getters.getPedido);
    const deliveredAgent = computed(
      () =>
        store.getters.getPedido?.orderInfo.orderState === OrderStatus.IN_TRANSIT
    );

    const confirmationRequestInfo = computed(
      () => store.getters.getConfirmationRequestInfo
    );

    const listening = ref(false);

    const dialogs = reactive({
      confirmed: {
        open: false
      },
      confirm: {
        open: props.value,
        address: "",
        updateAddress: ""
      }
    });

    watch(
      () => props.value,
      newValue => {
        dialogs.confirm.open = newValue;

        if (newValue) listening.value = true;
      }
    );

    watch(
      () => listening.value,
      newValue => {
        emit("hasEnterControl", newValue);
      }
    );

    watch(
      () => store.getters.getPedido,
      (order: Order) => {
        dialogs.confirm.address = order.deliveryAddress.addressLine;
      }
    );

    const sendRequest = () => {
      const orderValue: Order = order.value;
      const newAddress =
        dialogs.confirm.updateAddress === "yes"
          ? dialogs.confirm.address
          : undefined;

      store.dispatch("confirmDelivery", {
        order: orderValue,
        newAddress,
        motivo: deliveredAgent.value
          ? DeliveryConfirmationReason.RECEIVED_AGENT
          : DeliveryConfirmationReason.DELIVERED
      });
    };

    watch(
      () => [
        store.getters.getConfirmationRequestInfo.loading,
        store.getters.getConfirmationRequestInfo.error,
        store.getters.getConfirmationRequestInfo.is_rejecting
      ],
      ([loading, error, is_rejecting]) => {
        if (!is_rejecting && !loading && !error) {
          emit("input", false);
          dialogs.confirm.open = false;
          dialogs.confirm.updateAddress = "";

          dialogs.confirmed.open = true;

          emit("success");
        }
      }
    );

    const enterHandler = () => {
      const loadingRequest = confirmationRequestInfo.value.loading;
      const canConfirm =
        deliveredAgent.value || dialogs.confirm.updateAddress !== "";

      if (listening.value) {
        if (dialogs.confirmed.open) {
          dialogs.confirmed.open = false;
          listening.value = false;
        } else if (!loadingRequest && canConfirm) sendRequest();
      }
    };

    return {
      status,
      deliveredAgent,
      sendRequest,
      dialogs,
      listening,
      confirmationRequestInfo,
      enterHandler
    };
  }
});
