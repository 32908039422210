import { State } from "./state";
import { MutationTree } from "vuex";

const mutations: MutationTree<State> = {
  changeQueryParams(state: State, query: State) {
    state.idUser = query.idUser;
  }
};

export default mutations;
