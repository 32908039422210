













































import { defineComponent, reactive, watch } from "@vue/composition-api";

export default defineComponent({
  name: "DatePicker",
  props: {
    label: {
      type: String,
      default: "Date"
    },
    outlined: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array
    },
    value: {
      type: String
    },
    dense: {
      type: Boolean
    }
  },
  setup(props) {
    const state = reactive({
      date: props.value || undefined,
      inputValue: props.value || undefined
    });

    watch(
      () => props.value,
      newValue => {
        state.date = state.inputValue = newValue || undefined;
      }
    );

    return { state };
  }
});
