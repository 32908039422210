import { State } from "./state";
import { ActionTree, ActionContext } from "vuex";
import { findOne, listOrders, confirmDelivery } from "@/services/";
import {
  Order,
  OrderListParams,
  DeliveryConfirmationPayload
} from "@/interfaces/";
import i18n from "@/i18n";
import { DeliveryType, DeliveryConfirmationReason } from "@/enums";

const actions: ActionTree<State, State> = {
  async fetchList(
    context: ActionContext<State, State>,
    params: OrderListParams
  ) {
    context.commit("startLoading", "list");

    const orders = await listOrders(params);

    if (orders) {
      context.commit("loadPage", orders);
    } else {
      context.commit("stopLoading", "list");
    }
  },
  clearList(context: ActionContext<State, State>) {
    context.commit("clearPage");
  },
  async fetchOrder(context: ActionContext<State, State>, id_pedido: string) {
    const params: OrderListParams = {
      id_pedido
    };

    context.commit("startLoading", "view");

    const order = await findOne(params);

    if (order) {
      context.commit("changeOrder", order);
    } else {
      context.commit("stopLoading", "view");
    }
  },
  async confirmDelivery(
    context: ActionContext<State, State>,
    {
      order,
      newAddress,
      motivo,
      motivo_rechazo
    }: {
      order: Order;
      newAddress?: string;
      motivo: DeliveryConfirmationReason;
      motivo_rechazo?: string;
    }
  ) {
    context.commit("startLoading", "confirm");

    const direccion_normalizada =
      newAddress !== order.deliveryAddress.addressLine ? newAddress : undefined;

    const codigo_punto = order.pickUpInfo.warehouseCenter;

    const payload: DeliveryConfirmationPayload = {
      id_pedido: order.orderId,
      direccion_normalizada,
      codigo_punto,
      motivo
    };

    if (motivo_rechazo) payload.motivo_rechazo = motivo_rechazo;

    const result = await confirmDelivery(payload);

    const success = result && result.estado !== "FALLIDA";

    if (result?.estado === "FALLIDA") {
      alert(result.mensaje || i18n.t("api.defaultErrorMessage").toString());
    }

    context.commit("finishConfirmationRequest", {
      error: !success,
      is_rejecting: !!motivo_rechazo
    });
  }
};

export default actions;
