import instance from "@/services/centerCAVApi/instance";
import { to } from "nh-utils";
import { CAVCenterParams, Center } from "@/interfaces";
import i18n from "@/i18n";

const request = async (params: CAVCenterParams): Promise<Center | null> => {
  const [err, res] = await to(
    instance.get(
      `/warehouse/${params.where.codigo_centro + params.where.almacen}`
    )
  );

  if (err && !res) {
    alert(i18n.t("api.defaultErrorMessage").toString());
    return null;
  }

  const result = res.data;

  const center: Center = {
    name: result.warehouseName,
    address: [result.department, result.city, result.addressDetail]
      .filter(value => !!value)
      .join(", "),
    schedule: result.extInfor.workHourDesc
  };

  return center;
};

export default request;
