import instance from "@/services/shipmentApi/instance";
import { to } from "nh-utils";
import { DeliveryConfirmationPayload } from "@/interfaces";
import i18n from "@/i18n";

const request = async (body: DeliveryConfirmationPayload): Promise<any> => {
  const [err, res] = await to(instance.post("/pickup/confirm", body));

  if (err && !res) {
    alert(i18n.t("api.defaultErrorMessage").toString());
    return null;
  }

  return res?.data;
};

export default request;
