import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import routes from "./routes";

Vue.use(VueRouter);

const route: Array<RouteConfig> = [...routes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: route
});

router.beforeEach((to, _, next) => {
  if (to?.meta?.breadcrumb) {
    if (typeof to.meta.breadcrumb === "function") {
      to.meta.breadcrumb = to.meta.breadcrumb(to);
    }
  }
  next();
});

export const useRouter = () => router;

export default router;
