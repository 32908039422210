import { State } from "./state";
import { MutationTree } from "vuex";
import { Order, OrderList } from "@/interfaces";

const mutations: MutationTree<State> = {
  startLoading(state: State, which: "list" | "view" | "confirm") {
    state[which].loading = true;
  },
  stopLoading(state: State, which: "list" | "view" | "confirm") {
    state[which].loading = false;
  },
  clearPage(state: State) {
    state.list.page = 20;
    state.list.limit = 0;
    state.list.hasNextPage = true;
    state.list.total_objects = 0;
    state.list.total_pages = 0;
    state.list.pedidos = [];

    state.list.loading = false;
  },
  loadPage(state: State, result: OrderList) {
    state.list.page = result.page;
    state.list.limit = result.limit;
    state.list.hasNextPage = result.hasNextPage;
    state.list.total_objects = result.total_objects;
    state.list.total_pages = result.total_pages;
    state.list.pedidos = result.pedidos;

    state.list.loading = false;
  },
  changeOrder(state: State, result: Order) {
    state.view.order = result;
    state.view.loading = false;
  },
  finishConfirmationRequest(state: State, params: State["confirm"]) {
    state.confirm.error = params.error;
    state.confirm.is_rejecting = params.is_rejecting;
    state.confirm.loading = false;
  }
};

export default mutations;
