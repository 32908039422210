import { State } from "./state";
import { GetterTree } from "vuex";

const getters: GetterTree<State, State> = {
  getConfirmationRequestInfo(state: State) {
    return state.confirm;
  },
  getListInfo(state: State) {
    const { loading, limit, page, total_objects, total_pages } = state.list;

    return {
      loading,
      limit,
      page,
      total_objects,
      total_pages
    };
  },
  getPedidos(state: State) {
    return state.list.pedidos;
  },
  getViewLoading(state: State) {
    return state.view.loading;
  },
  getPedido(state: State) {
    return state.view.order;
  }
};

export default getters;
