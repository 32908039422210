import { Center } from "@/interfaces";

interface CenterStateInterface {
  center: {
    data: Center | null;
    loading: boolean;
  };
}

const state: CenterStateInterface = {
  center: {
    data: null,
    loading: false
  }
};

export type State = typeof state;
export default state;
