




















































import { defineComponent, reactive, ref } from "@vue/composition-api/";

export default defineComponent({
  setup() {
    const menuItems = reactive([{ title: "Gestión de Ordenes", link: "/" }]);

    const menuIsOpen = ref(false);

    const toggleMenu = () => {
      menuIsOpen.value = !menuIsOpen.value;
    };

    return { menuItems, menuIsOpen, toggleMenu };
  }
});
