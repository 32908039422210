import { State } from "./state";
import { ActionTree, ActionContext } from "vuex";
import { findCenterAgent, findCenterCAV } from "@/services/";
import {
  Center,
  Order,
  AgentCenterParams,
  CAVCenterParams
} from "@/interfaces/";
import { DeliveryType } from "@/enums";

const actions: ActionTree<State, State> = {
  async fetchCenter(context: ActionContext<State, State>, order: Order) {
    context.commit("startLoadingCenter");
    let center: Center | null = null;

    if (order.orderInfo.deliveryType === DeliveryType.AGENT) {
      const params: AgentCenterParams = {
        where: {
          dealer_hijo:
            order.pickUpInfo.warehouseCenter + order.pickUpInfo.warehouseCode
        }
      };

      center = await findCenterAgent(params);
    } else {
      const { pickUpInfo } = order;

      const params: CAVCenterParams = {
        where: {
          codigo_centro: pickUpInfo.warehouseCenter,
          almacen: pickUpInfo.warehouseCode
        }
      };

      center = await findCenterCAV(params);
    }

    if (center) {
      context.commit("changeCenter", center);
    } else {
      context.commit("stopLoadingCenter");
    }
  }
};

export default actions;
