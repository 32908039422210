






































import { defineComponent, reactive, watch } from "@vue/composition-api";
import { KeyboardEventHandler } from "@/components/";

export default defineComponent({
  name: "FilterBox",
  components: {
    KeyboardEventHandler
  },
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  emits: ["search", "clear"],
  setup(props, { emit }) {
    const state = reactive({
      valid: props.value,
      openPanel: 0
    });

    watch(
      () => props.value,
      newValue => {
        if (state.valid !== newValue) state.valid = newValue;
      }
    );

    return {
      state,
      onChange: (valid: boolean) => emit("input", valid)
    };
  }
});
