
























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    name: {
      type: String
    },
    address: {
      type: String
    },
    opens: {
      type: String
    }
  }
});
