interface QueryStateInterface {
  idUser: string | null;
}

const state: QueryStateInterface = {
  idUser: null
};

export type State = typeof state;
export default state;
